@import url("https://fonts.googleapis.com/css?family=Open+Sans");

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
}

.ais-InstantSearch__root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

a {
  color: #5468ff;
}

.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
  font-style: normal;
  font-weight: bold;
  text-decoration: underline;
}

.ais-InstantSearch__root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.search-container {
  flex-grow: 1;
  display: flex;
  flex-direction: row-reverse;
  width: 960px;
  min-height: 100%;
  margin: 0 auto;
}

@media (max-width: 1007px) {
  .search-container {
    width: 100%;
  }
}

#categories {
  margin-right: 24px;
  padding: 8px 0;
}

.ais-SearchBox-input {
  border-radius: 25px !important;
}

#categories,
.ais-Hits-item,
.ais-SearchBox-input {
  height: auto;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.ais-SearchBox-input:focus {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.left-panel {
  flex: 0 0 240px;
}

.right-panel {
  width: calc(100% - 256px);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

#searchbox {
  flex-grow: 0;
  margin-bottom: 8px;
}

#searchbox,
#stats {
  flex-grow: 0;
}

.ais-SearchBox {
  position: relative;
  height: auto;
  width: 100%;
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  position: absolute;
  top: 0;
  left: inherit;
  margin: 14px 16px;
  cursor: pointer;
}

.ais-SearchBox-submit svg,
.ais-SearchBox-reset svg {
  width: 13px;
  height: 13px;
  display: block;
}

.ais-SearchBox-submit svg {
  fill: #5468ff;
}

.ais-SearchBox-reset svg {
  fill: #ed5a6a;
}

.ais-SearchBox-submit {
  right: 0;
}

.ais-SearchBox-reset {
  background: none;
  padding: 0;
  border: none;
  right: 30px;
}

.ais-SearchBox-input {
  width: 100%;
  padding: 8px 16px;
  font-weight: normal;
  border: none;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  appearance: none;
}

#stats {
  flex-grow: 0;
  margin-bottom: 16px;
}

.ais-Stats {
  font-size: 12px;
  color: #697782;
}

#hits {
  flex-grow: 1;
  order: 1;
}

.ais-Panel-header {
  font-weight: bolder;
  margin: 0;
  padding: 0 16px 8px;
  font-size: inherit;
  text-transform: uppercase;
  border-bottom: 1px solid #eee;
}

.ais-Hits-item {
  margin-bottom: 24px;
}

.ais-Hits-item article {
  display: flex;
}

.ais-Hits-item .entry-author-content {
  font-size: 13px;
}

.ais-Hits-item .post-date,
.ais-Hits-item .post-meta-info {
  color: #999;
  font-size: 12px;
}

.ais-Hits-item .entry-meta {
  display: flex;
}

.ais-Hits-item .entry-title a {
  text-decoration: none;
}

.ais-Hits-item .author-gravatar img {
  border-radius: 50%;
  margin-right: 8px;
  display: block;
}

.ais-Hits-item .post-img a,
.ais-Hits-item .post-img img {
  width: 100%;
  display: block;
}

@media (min-width: 768px) {
  .ais-Hits-item .post-img {
    flex: 0 0 240px;
    overflow: hidden;
  }

  .ais-Hits-item .post-img,
  .ais-Hits-item .post-img a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ais-Hits-item .post-img a {
    height: 100%;
  }

  .ais-Hits-item .post-img img {
    width: auto;
    height: 100%;
    display: block;
  }
}

@media (max-width: 767px) {
  .ais-Hits-item article {
    flex-direction: column;
  }
}

.ais-Hits-item .post-excerpt {
  margin-bottom: 16px;
}

.ais-Hits-item .entry-title {
  margin: 0 0 16px 0;
  font-size: 16px;
  font-weight: normal;
}

.ais-Hits-item .post-content {
  padding: 16px;
}

.ais-RefinementList-item {
  padding: 8px 16px;
}

.ais-RefinementList-label {
  cursor: pointer;
}

.ais-RefinementList-labelText,
.ais-RefinementList-labelText:hover {
  color: black;
  cursor: pointer;
}

.ais-RefinementList-count {
  background: none;
  padding: 0;
  font-size: 12px;
  line-height: 24px;
  color: #999;
  float: right;
}

.ais-RefinementList-checkbox {
  margin: 0;
}

.ais-RefinementList-labelText {
  margin-left: 0.6em;
}

.ais-RefinementList-item--selected .ais-RefinementList-label {
  font-weight: 700;
}

.ais-RefinementList-item--selected .ais-RefinementList-count {
  font-weight: normal;
}

#pagination {
  order: 1;
}

.ais-Pagination-list {
  display: flex;
  margin: 0 0 16px;
  padding: 0;
  background: none;
  list-style: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  box-shadow: none;
  flex-wrap: wrap;
}

.ais-Pagination-link {
  display: block;
}

.ais-Pagination-item,
.ais-Pagination-item--disabled {
  flex: 0 0 40px;
  width: auto;
  height: auto;
  margin: 4px;
  background-color: #5468ff;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  text-align: center;
  border-radius: 4px;
  transition: all 0.1s linear;
}

.ais-Pagination-item--disabled {
  display: none;
}

.ais-Pagination-item:hover,
.ais-Pagination-item--selected {
  background-color: #3a46a1;
}

.ais-Pagination-item .ais-Pagination-link,
.ais-Pagination-item--disabled .ais-Pagination-link {
  text-decoration: none;
  color: #fff;
  line-height: 32px;
}

footer {
  flex-grow: 0;
  text-align: center;
  font-size: 12px;
  color: #999;
}
