::-webkit-scrollbar {
  /* 	width: 8px; */
  outline: 1px solid #000;
}

::-webkit-scrollbar-track {
  background-color: #000 !important;
}

::-webkit-scrollbar-thumb {
  background: rgb(85, 130, 149) !important;
  border-radius: 20px;
  outline: 1px solid #000;
}

@-moz-document url-prefix() {
  #driveItem svg {
    display: none;
  }
}
